import { ArcherContainer, ArcherElement } from "react-archer";
import { useFullScrollCycle } from "../../hooks/useScroll";
import { getSvg, SvgKeys } from "./svgs";

interface ImageProps {
  url: string;
  imageScrollSpeed?: number;
  imageTranslation?: string;
  imageScale?: number;
}

interface CardProps {
  title: string;
  description: string;
  category: string;
  categoryColor?: string;
  cardTranslation: string;
  cardScrollSpeed?: number;
  cardTitleSize?: number;
  techUsed: SvgKeys[];
}

interface ImageCardProps {
  invert?: boolean;
  imageProps: ImageProps;
  cardProps: CardProps;
  hideStroke?: boolean;
}

const DEFAULT_SCROLL_SPEED = 0.03;
const DEFAULT_COLOR = "blue";
const DEFAULT_TRANSLATION = "";
const DEFAULT_SCALE = 1.4;
const DEFAULT_TITLE_SIZE = 4;

const sizeMap: Record<number, string> = {
  2: "text-2xl",
  3: "text-2xl lg:text-3xl",
  4: "text-3xl lg:text-4xl",
  5: "text-5xl",
};

const colorMap: Record<string, string> = {
  red: "text-red-800 border-red-400 dark:text-red-400",
  blue: "text-blue-med border-blue-light dark:text-blue-light",
  green: "text-green-800 border-green-400 dark:text-green-400",
  yellow: "text-yellow-800 border-yellow-400 dark:text-yellow-400",
  gray: "text-gray-800 border-gray-400 dark:text-gray-400",
};

export const ImageCard = ({
  invert = false,
  imageProps,
  cardProps,
  hideStroke = false,
}: ImageCardProps) => {
  const {
    url: imageUrl,
    imageScrollSpeed = DEFAULT_SCROLL_SPEED,
    imageTranslation = DEFAULT_TRANSLATION,
    imageScale = DEFAULT_SCALE,
  } = imageProps;
  const {
    title,
    description,
    category,
    categoryColor = DEFAULT_COLOR,
    cardScrollSpeed = DEFAULT_SCROLL_SPEED,
    cardTitleSize = DEFAULT_TITLE_SIZE,
    cardTranslation,
    techUsed,
  } = cardProps;

  const { handleScroll } = useFullScrollCycle();
  const appliedCategoryColor = colorMap[categoryColor] || colorMap["blue"];
  const appliedTitleSize = sizeMap[cardTitleSize] || sizeMap[4];

  return (
    <ArcherContainer strokeColor="gray">
      <div
        style={{ height: "35rem" }}
        className={`flex  md-lg:flex-row ${
          invert ? "flex-col md-lg:flex-row-reverse" : "flex-col md-lg:flex-row"
        } w-full`}
      >
        {/* Content */}
        <div
          className="h-full w-2/6 pl-20"
          style={{
            ...handleScroll(cardScrollSpeed),
            transform: cardTranslation,
          }}
        >
          <div className="flex flex-col items-center justify-center h-full">
            <ArcherElement
              id="root"
              relations={[
                {
                  targetId: "element2",
                  targetAnchor: "top",
                  sourceAnchor: invert ? "left" : "right",
                  style: {
                    strokeColor: !hideStroke
                      ? "rgba(55, 65, 81, 1)"
                      : "transparent",
                    strokeWidth: 1,
                  },
                },
              ]}
            >
              <div
                className={`relative border border-gray-600 border-opacity-40 p-10 rounded-lg`}
                style={{
                  background:
                    "linear-gradient(to bottom,rgba(228, 221, 221, 0.03),rgba(212, 210, 210, 0.07))",
                }}
              >
                <span
                  className={`font-body px-2.5 py-0.5 rounded-full text-xs font-medium me-2 border ${appliedCategoryColor}`}
                >
                  {category}
                </span>
                <h2
                  className={`font-title tracking-wider text-white ${appliedTitleSize} font-bold mb-5 mt-2`}
                >
                  {title}
                </h2>

                <p className="font-body text-gray-200 font-thin sm:text-xs lg:text-sm">
                  {description}
                </p>

                {/*  Tech Used */}
                <div className="mt-5 flex gap-4">
                  {techUsed.map((tech, index) => (
                    <div key={index}>{getSvg(tech)}</div>
                  ))}
                </div>
              </div>
            </ArcherElement>
          </div>
        </div>

        {/* Image */}
        <div
          className={`${
            invert ? "mt-20" : "mt-0"
          } h-full w-6/6 md-lg:w-4/6 px-20`}
          style={{
            ...handleScroll(imageScrollSpeed),
          }}
        >
          <ArcherElement id="element2">
            <img
              style={{ transform: `scale(${imageScale}) ${imageTranslation}` }}
              className="h-full w-full object-contain"
              src={imageUrl}
              alt=""
            />
          </ArcherElement>
        </div>
      </div>
    </ArcherContainer>
  );
};
