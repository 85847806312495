import { useEffect, useMemo, useRef, useState } from 'react';
import { TimeLineCard } from '../../components/timeline-card/TimeLineCard';
import { useMediaQuery } from '@react-hook/media-query';

const BASE = 3000;
const MOBILE_BASE = 3100;
const XS_BASE = 2700;

const MAX_LINE_HEIGHT = 116;

export const PageThree = () => {
  const [lineHeight, setLineHeight] = useState(0);
  const topRef = useRef<HTMLDivElement>(null);

  const isDesktop = useMediaQuery('only screen and (min-width: 1100px)');
  const isXS = useMediaQuery('only screen and (max-width: 600px)');

  // const [y, setY] = useState(0);
  // console.log('y', y);

  const divRef = useRef<HTMLDivElement>(null);

  const base = useMemo(() => {
    if (isXS) {
      return XS_BASE;
    }
    return isDesktop ? BASE : MOBILE_BASE;
  }, [isDesktop, isXS]);

  useEffect(() => {
    const updatePosition = () => {
      const endLineGrowth = MAX_LINE_HEIGHT * TIMELINE.length - 1 + 8;
      const dimension = (window.scrollY - base) / 2;
      // setY(window.scrollY);

      if (dimension < endLineGrowth) {
        setLineHeight((window.scrollY - base) / 2);
      }
    };

    window.addEventListener('scroll', updatePosition);
    updatePosition(); // Initialize with current scroll position

    return () => {
      window.removeEventListener('scroll', updatePosition);
    };
  }, []);

  return (
    <div className="relative min-h-screen bg-black">
      <h1
        style={{ zIndex: 999 }}
        className="relative pt-20 ml-5 mr-0 text-5xl font-bold tracking-wider text-white font-title sm:ml-20 md:mr-32 sm:text-6xl md:text-7xl lg:text-8xl"
      >
        PROFESSIONAL
      </h1>

      <div className="top-[0px] sm:top-[40px] md:top-[60px] lg:top-[80px] transform scale-[200%] xs:scale-[160%] sm:scale-[120%] md:scale-[120%] lg:scale-100 absolute right-0 h-full w-full opacity-10">
        <img
          className="object-contain w-full h-full"
          src="/images/2.jpg"
          alt=""
        />
      </div>

      <div
        className="absolute top-0 left-0 w-3/6 h-full"
        style={{
          background:
            'linear-gradient(to right, black 20%, rgb(0, 0, 0) 85%, rgb(0, 0, 0) 60%, transparent 100%)',
        }}
      />

      <div className="flex flex-col items-center justify-center mt-20">
        <div
          style={{ width: 'auto' }}
          className="h-50 relative pl-[4.5rem] sm:pl-20 lg:pl-32 py-6 group"
        >
          <div className="mb-1 text-2xl font-medium text-white md:text-3xl md:mb-0">
            <p ref={topRef} className="ml-3 w-52 md:w-60 font-title">
              Software Engineer II
            </p>
          </div>

          <div className="flex flex-col items-start mb-1">
            <span
              className={`font-body absolute left-0 translate-y-0.5  px-2.5 py-0.5 rounded-full text-[10px] md:text-xs font-thin me-2 border text-blue-med border-blue-light dark:text-blue-light`}
            >
              Present
            </span>
            <div className="flex flex-row items-center text-2xl font-thin text-gray-400">
              <div
                ref={divRef}
                className="relative w-2 h-2 mr-2 bg-gray-400 rounded-full"
              />
              <p className="font-body text-[1.2rem] lg:text-[1.5rem]">Orium</p>
              <div
                style={{
                  height: `${lineHeight}px`,
                }}
                className="transform translate-y-[80px] translate-x-[3.5px] w-[1px] top-0 absolute bg-blue-light"
              />
            </div>
          </div>
        </div>

        <div>
          {TIMELINE.map((time, idx) => (
            <TimeLineCard
              key={idx}
              title={time.title}
              date={time.date}
              company={time.company}
              isVisible={lineHeight >= MAX_LINE_HEIGHT * (idx + 1)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const TIMELINE = [
  { title: 'Software Engineer', date: 'Jan 2022', company: 'Orium' },
  { title: 'Lead Developer', date: 'Jul 2020', company: 'ServUs Health' },
  { title: 'Full Stack Developer', date: 'Mar 2018', company: 'ServUs Health' },
];
