import { useInView } from 'react-intersection-observer';
import { getSvg, SvgKeys } from '../image-card/svgs';
import { useEffect, useState } from 'react';

interface CardProps {
  category: string;
  title: string;
  techUsed: SvgKeys[];
  description: string;
  categoryColor?: string;
}

interface ImageCardMobileProps {
  imageUrl: string;
  cardProps: CardProps;
  idx: number;
}

const colorMap: Record<string, string> = {
  red: 'text-red-800 border-red-400 dark:text-red-400',
  blue: 'text-blue-med border-blue-light dark:text-blue-light',
  green: 'text-green-800 border-green-400 dark:text-green-400',
  yellow: 'text-yellow-800 border-yellow-400 dark:text-yellow-400',
  gray: 'text-gray-800 border-gray-400 dark:text-gray-400',
};

const DEFAULT_COLOR = 'blue';

const handleScrollEffect = (position: number, speed: number) => {
  return position * speed;
};

const handleScroll = (position: number, speed: number) => {
  return {
    transform: `translateY(-${handleScrollEffect(position, speed)}%)`,
  };
};

export const ImageCardMobile = ({
  imageUrl,
  cardProps,
  idx,
}: ImageCardMobileProps) => {
  const {
    category,
    title,
    techUsed,
    description,
    categoryColor = DEFAULT_COLOR,
  } = cardProps;
  const appliedCategoryColor = colorMap[categoryColor] || colorMap['blue'];
  const [position, setPosition] = useState(0);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  });

  const [initialScrollY, setInitialScrollY] = useState<number | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (inView) {
        if (initialScrollY === null) {
          setInitialScrollY(window.scrollY); // Store initial scrollY when entering
        }
        const newPosition = window.scrollY - (initialScrollY ?? 0);
        setPosition(Math.max(0, newPosition)); // Prevent negative values
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [inView, initialScrollY]); // Depend on inView and initialScrollY

  const isFullHeight = idx === 4;
  const heightClass = isFullHeight ? 'sm:h-[70vh]' : 'sm:h-[65vh]';
  // const heightClass = 'sm:h-[65vh]';

  return (
    <div
      className={`${inView ? 'opacity-100' : 'opacity-0'} ${
        idx !== 1 ? 'mt-[-6rem]' : ''
      }  xs:mt-0 h-[65vh] xs:h-[55vh] ${heightClass} transition-opacity transition-1000 delay-300 p-5`}
    >
      {/* Card */}
      <div
        ref={ref}
        style={{
          ...(position > 0 ? handleScroll(position, 0.03) : {}),
          background:
            'linear-gradient(to bottom,rgba(228, 221, 221, 0.03),rgba(212, 210, 210, 0.07))',
        }}
        className={`
          // inView ? 'opacity-100' : 'opacity-0'
         "w-full h-auto p-8 border border-gray-600 rounded-lg border-opacity-40 xs:h-auto xs:w-80 sm:h-auto sm:w-80`}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <span
              className={`font-body px-2.5 py-0.5 rounded-full text-xs font-medium me-2 border ${appliedCategoryColor}`}
            >
              {category}
            </span>
            <h2
              className={`font-title tracking-wider text-white text-2xl sm:text-3xl font-bold mb-5 mt-2`}
            >
              {title}
            </h2>

            <p className="text-xs font-thin text-gray-200 font-body md:text-sm">
              {description}
            </p>
          </div>

          {/* Tech Used */}
          <div className="flex gap-4 mt-5">
            {techUsed.map((tech, index) => (
              <div key={index}>{getSvg(tech)}</div>
            ))}
          </div>
        </div>
      </div>

      {/* Image*/}
      <div
        // ref={refImg}
        // className={`${inViewImg ? 'opacity-100' : 'opacity-0'}`}
        style={{
          ...(position > 0 ? handleScroll(position, 0.075) : {}),
          // marginTop: idx === 1 ? "5vh" : `${idx * 23}vh`,
        }}
      >
        <img
          className="transform scale-[125%] xs:scale-100"
          src={imageUrl}
          alt=""
        />
      </div>
    </div>
  );
};
