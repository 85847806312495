import clsx from "clsx";
import { useFadeIn } from "../../hooks/useFadeIn";
import { FADE_DURATION_HEADER } from "../../animations";

const TEXT_INACTIVE_COLOR = "text-gray-500 opacity-40";

const NAV_ITEM_CLASS =
  "tracking-wider text-sm md:text-md lg:text-lg cursor-pointer font-title transition-transform duration-200 hover:scale-105";

export const Header = ({ inView }: { inView: boolean }) => {
  const fadeInClass = useFadeIn(FADE_DURATION_HEADER);

  return (
    <header
      className={`fixed top-0 left-0 my-12 mx-5 md:my-12 md:mx-10  lg:my-12 lg:mx-12 ${fadeInClass} flex`}
      style={{ zIndex: 1000, width: "90%" }}
    >
      <p
        style={{ transitionDuration: "250ms" }}
        className={clsx(
          "text-xl md:text-2xl lg:text-3xl transition-all font-medium font-title tracking-wider",
          inView ? TEXT_INACTIVE_COLOR : "text-blue-med"
        )}
      >
        BEN SVEN <span className="hidden xs:inline-block">TEN</span>
      </p>

      <div
        className={clsx(
          "flex gap-1 md:gap-3 lg:gap-4 font-bold ml-auto transition-all duration-500 text-sm items-center",
          inView ? TEXT_INACTIVE_COLOR : "text-white"
        )}
      >
        <a href="#home" className={NAV_ITEM_CLASS}>
          HOME
        </a>

        <p className="text-gray-500">&#x2022;</p>
        <a href="#projects" className={NAV_ITEM_CLASS}>
          PROJECTS
        </a>
        <p className="text-gray-500">&#x2022;</p>
        <a href="#contact" className={NAV_ITEM_CLASS}>
          CONTACT
        </a>
      </div>
    </header>
  );
};
