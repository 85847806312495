import { useCallback, useEffect, useState } from "react";
import { useFullScrollCycle } from "../../hooks/useScroll";
import { useFadeIn } from "../../hooks/useFadeIn";
import { FADE_DURATION_SUBTEXT, FADE_DURATION_TITLE } from "../../animations";

const MAX_SCROLL_POSITION = 917; // one page scroll cycle

const calculateGradientPercentage = (scrollPosition: number) => {
  return 100 * (1 - scrollPosition / MAX_SCROLL_POSITION);
};

export const PageOne = () => {
  const { position, handleScroll } = useFullScrollCycle();
  const [gradientPercentage, setGradientPercentage] = useState(100);
  const fadeInTitle = useFadeIn(FADE_DURATION_TITLE);
  const fadeInSubText = useFadeIn(FADE_DURATION_SUBTEXT);
  const fadeInIcons = useFadeIn(FADE_DURATION_SUBTEXT + 1000);
  const fadeInSideText = useFadeIn(FADE_DURATION_SUBTEXT + 1000, 60);

  const handleSetGradient = useCallback(() => {
    setGradientPercentage(calculateGradientPercentage(position / 2));
  }, [position]);

  useEffect(() => {
    window.addEventListener("scroll", handleSetGradient, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleSetGradient);
    };
  }, [position, handleSetGradient]);

  return (
    <div
      id="home"
      className="h-screen bg-black bg-opacity-10 flex relative"
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 1) ${gradientPercentage}%)`,
      }}
    >
      <div className="w-screen flex flex-col justify-center p-5 sm:p-8 md:p-10 lg:p-20">
        <div className="flex items-center">
          <div
            className={`mr-2 xs:mr-5 flex flex-col gap-4 ${fadeInIcons}`}
            style={{
              ...handleScroll(0.3),
            }}
          ></div>

          <div
            className={fadeInTitle}
            style={{ position: "relative", zIndex: 10 }}
          >
            <h1 className="text-white text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-bold font-title">
              DEVELOPER{" "}
              <span className="xs:inline-block hidden text-5xl text-blue-med">
                &
              </span>
            </h1>
            <h1
              className="text-white text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-title font-bold ml-2 xs:ml-5 tracking-wider"
              style={{
                ...handleScroll(0.1),
              }}
            >
              <span className="mr-2 xs:hidden inline-block text-5xl text-blue-med">
                &
              </span>
              CREATOR
            </h1>
          </div>
        </div>
        <div
          className={`w-6/6 xs:w-4/6 sm:w-4/6 md:w-4/6 lg:w-1/2 font-body ml-2 xs:ml-6 sm:ml-8 md:ml-8 lg:ml-8 md:ml-10 lg:ml-20 text-gray-300 font-thin text-sm md:text-lg lg:text-xl mt-6 ${fadeInSubText}`}
          style={{
            ...handleScroll(0.2),
            position: "relative",
            zIndex: 5,
          }}
        >
          I’m a <span className="text-blue-light font-bold">creative</span> and
          <span className="text-blue-light font-bold"> passionate</span>{" "}
          full-stack engineer with nearly a decade of experience, spanning
          industries from eCommerce to HealthTech. With a strong foundation in a
          variety of technologies, I bring innovative solutions to every
          project. Check out some of my client{" "}
          <span className="relative group font-title cursor-pointer relative text-blue-light font-lg transition-color duration-200 hover:text-blue-light">
            <a href="#projects">projects.</a>
            <div className="h-[1px] opacity-60 w-[80%] absolute right-[0] bottom-[-3px] bg-blue-light transition-transform duration-200 group-hover:translate-x-[-8px]" />
            <div className="bottom-[-4px] md:bottom-[-6px] right-[-8px] h-[1px] opacity-60 w-[80%] absolute bg-blue-light transition-transform duration-200 group-hover:translate-x-[8px]" />
          </span>
        </div>
      </div>

      <div>
        <div
          className={`transform translate-y-[-100%] rotate-90 absolute right-[-5rem] md:right-[-5rem] lg:right-[-2rem] top-[90%] text-white font-thin flex ${fadeInSideText}`}
        >
          <p className="font-body text-[8px] xs:text-xs text-gray-400">
            {" "}
            © BEN SHEKHTMAN {new Date().getFullYear()}
          </p>
          <div className="transform translate-y-[8px] bg-gray-400 ml-[10px] w-[100px] md:w-[150px] lg:w-[200px] h-[.5px]" />
        </div>
      </div>
    </div>
  );
};
