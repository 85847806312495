import { useEffect, useMemo, useState } from 'react';
import {
  ContactCard,
  ContactInfo,
} from '../../components/comtact-card/ContactCard';
import { useMediaQuery } from '@react-hook/media-query';

export const PageFour = () => {
  const isDesktop = useMediaQuery('only screen and (min-width: 640px)');

  const contactInfo = useMemo(() => {
    if (isDesktop) {
      return CONTACT_INFO;
    }

    return CONTACT_INFO.map((info) => {
      return {
        ...info,
        scrollSpeed: 0.01,
      };
    });
  }, [isDesktop]);

  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1;
      setIsBottom(scrolledToBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      id="contact"
      className="flex flex-col min-h-screen bg-black bg-opacity-100"
    >
      <h1 className="pt-20 mb-20 ml-5 text-5xl font-bold tracking-wider text-white font-title md:ml-20 sm:mr-5 md:mr-32 sm:text-6xl md:text-7xl lg:text-8xl">
        GET{' '}
        <span className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl text-blue-light">
          IN
        </span>{' '}
        TOUCH
      </h1>

      {/* Contact form */}
      <div className="pt-16 bg-black">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-10 pb-10 lg:grid-cols-3">
              <div />
              <div className="flex flex-col items-center gap-6 sm:grid sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                {contactInfo.map((contact, index) => (
                  <ContactCard key={index} info={contact} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-auto transition-all duration-1000 ${
          isBottom ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <p className="relative text-gray-600 font-body text-[12px] xs:text-sm font-thin text-center p-5">
          © BEN SHEKHTMAN {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

const CONTACT_INFO: ContactInfo[] = [
  {
    title: 'LinkedIn',
    svg: 'linkedIn',
    scrollSpeed: 0.01,
    href: 'https://linkedin.com/in/ben-shekhtman',
    handle: 'benshekhtman',
  },
  {
    title: 'Github',
    svg: 'github',
    scrollSpeed: 0.015,
    href: 'https://github.com/uwitdat',
    handle: 'uwitdat',
  },
  {
    title: 'Instagram',
    svg: 'instagram',
    scrollSpeed: 0.01,
    href: 'https://www.instagram.com/ten_ben_sven',
    handle: 'ten_ben_sven',
  },
  {
    title: 'X',
    svg: 'x',
    scrollSpeed: 0.015,
    href: 'https://x.com/ben_sven_ten',
    handle: 'ben_sven_ten',
  },
];
