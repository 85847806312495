import { FADE_DURATION_IMAGE } from "../../animations";
import { useFadeIn } from "../../hooks/useFadeIn";

export const ImageOverlay = () => {
  const fadeInClass = useFadeIn(FADE_DURATION_IMAGE);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black">
      <img
        className={`w-full h-full object-cover ${fadeInClass}`}
        src="/images/1.jpg"
        alt="Background"
      />
    </div>
  );
};
