import { Header } from "./components/header/Header";
import { PageOne } from "./pages/page-one";
import { ImageOverlay } from "./components/image-overlay/ImageOverlay";
import { PageTwoV2 } from "./pages/page-two-v2";
import { PageThree } from "./pages/page-three";
import { useInView } from "react-intersection-observer";
import { FADE_DURATION_SUBTEXT } from "./animations";
import { useFirstLoad } from "./hooks/useFirstLoad";
import { PageFour } from "./pages/page-four";

function App() {
  useFirstLoad(FADE_DURATION_SUBTEXT + 200);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  return (
    <div>
      {/* Header */}
      <Header inView={inView} />

      {/* Fixed Background Image */}
      <ImageOverlay />

      {/* Content  */}
      <main className="relative z-10">
        <PageOne />
        <div ref={ref}>
          <PageTwoV2 />
          <PageThree />
          <PageFour />
        </div>
      </main>
    </div>
  );
}

export default App;
