import { useInView } from 'react-intersection-observer';
import { ImageCard } from '../../components/image-card/ImageCard';
import { useMediaQuery } from '@react-hook/media-query';
import { ImageCardMobile } from '../../components/image-card-mobile/ImageCardMobile';

const ANIMATION_DURATION = 'duration-500';

export const PageTwoV2 = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.3,
  });

  const { ref: refTwo, inView: inViewTwo } = useInView({
    /* Optional options */
    threshold: 0.2,
  });

  const { ref: refThree, inView: inViewThree } = useInView({
    /* Optional options */
    threshold: 0.2,
  });

  const { ref: refFour, inView: inViewFour } = useInView({
    /* Optional options */
    threshold: 0.2,
  });

  const isDesktop = useMediaQuery('only screen and (min-width: 1100px)');

  return (
    <div
      className="flex flex-col min-h-screen bg-black bg-opacity-100"
      id="projects"
    >
      <h1 className="pt-20 pb-20 ml-5 mr-5 text-5xl font-bold tracking-wider text-white font-title md-lg:pb-0 md:ml-20 md:mr-32 sm:text-6xl md:text-7xl lg:text-8xl">
        CLIENT WORK
      </h1>

      <div className="flex flex-col gap-8 sm:gap-10 md:gap-14 lg:gap-20">
        {isDesktop ? (
          <div
            ref={ref}
            className={`${
              inView ? 'opacity-100' : 'opacity-0'
            } transition-opacity ${ANIMATION_DURATION}`}
          >
            <ImageCard
              hideStroke
              imageProps={{
                url: '/project-images/10.png',
                imageTranslation: 'translateY(100px) translateX(40px)',
                imageScrollSpeed: 0.05,
                imageScale: 1.2,
              }}
              cardProps={{
                cardTitleSize: 4,
                cardScrollSpeed: 0.03,
                category: 'E Commerce',
                title: 'Made Digital',
                techUsed: ['react', 'remix', 'node', 'tailwind', 'typeScript'],
                cardTranslation: 'translateY(2px) translateX(40px)',
                description:
                  'A Landing page for a digital marketing agency. The goal was to create a modern and clean design that would attract new clients.',
              }}
            />
          </div>
        ) : (
          <ImageCardMobile
            idx={1}
            imageUrl="/project-images/10.png"
            cardProps={{
              category: 'E Commerce',
              title: 'Made Digital',
              techUsed: ['react', 'remix', 'node', 'tailwind', 'typeScript'],
              description:
                'A Landing page for a digital marketing agency. The goal was to create a modern and clean design that would attract new clients.',
            }}
          />
        )}

        {isDesktop ? (
          <div
            ref={refTwo}
            className={`${
              inViewTwo ? 'opacity-100' : 'opacity-0'
            } transition-opacity ${ANIMATION_DURATION}`}
          >
            <ImageCard
              invert
              imageProps={{
                url: '/project-images/3.png',
                imageTranslation: 'translateX(40px)',
                imageScrollSpeed: 0.04,
                imageScale: 1.2,
              }}
              cardProps={{
                category: 'Marketing',
                categoryColor: 'red',
                cardTitleSize: 3,
                title: 'Eagle Music Services',
                cardTranslation: 'translateX(-25px)',
                techUsed: ['react', 'astroLight', 'typeScript', 'tailwind'],
                description:
                  'A website for a music production company. Integrated social media & promotional videos to help improve user engagement.',
              }}
            />
          </div>
        ) : (
          <ImageCardMobile
            idx={2}
            imageUrl="/project-images/3.png"
            cardProps={{
              category: 'Marketing',
              categoryColor: 'red',
              title: 'Eagle Music Services',
              techUsed: ['react', 'astroLight', 'typeScript', 'tailwind'],
              description:
                'A website for a music production company. Integrated social media & promotional videos to help improve user engagement.',
            }}
          />
        )}

        {isDesktop ? (
          <div
            ref={refThree}
            className={`${
              inViewThree ? 'opacity-100' : 'opacity-0'
            } transition-opacity ${ANIMATION_DURATION}`}
          >
            <ImageCard
              imageProps={{
                url: '/project-images/12.png',
                imageTranslation: 'translateY(230px) translateX(40px)',
                imageScrollSpeed: 0.05,
                imageScale: 1.2,
              }}
              cardProps={{
                cardScrollSpeed: 0.03,
                category: 'SaaS',
                categoryColor: 'green',
                title: 'Smart Tech Solutions',
                cardTitleSize: 3,
                techUsed: [
                  // "react",
                  'nextLight',
                  'openAI',
                  'tailwind',
                  'typeScript',
                  // "node",
                  'python',
                  // "whatsApp",
                  // "next",
                ],
                cardTranslation: 'translateY(-150px) translateX(40px)',
                description:
                  'A SaaS application with AI integration for automated chat via WhatsApp.',
              }}
            />
          </div>
        ) : (
          <ImageCardMobile
            idx={3}
            imageUrl="/project-images/12.png"
            cardProps={{
              category: 'SaaS',
              categoryColor: 'green',
              title: 'Smart Tech Solutions',
              techUsed: [
                // "react",
                'nextLight',
                'openAI',
                'tailwind',
                'typeScript',
                // "node",
                'python',
                // "whatsApp",
                // "next",
              ],
              description:
                'A SaaS application with AI integration for automated chat via WhatsApp.',
            }}
          />
        )}

        {isDesktop ? (
          <div
            ref={refFour}
            className={`${
              inViewFour ? 'opacity-100' : 'opacity-0'
            } transition-opacity ${ANIMATION_DURATION}`}
          >
            <ImageCard
              invert
              imageProps={{
                url: '/project-images/11.png',
                imageTranslation: 'translateY(230px) translateX(1px)',
                imageScrollSpeed: 0.05,
                imageScale: 1.2,
              }}
              cardProps={{
                cardScrollSpeed: 0.03,
                category: 'SaaS',
                categoryColor: 'green',
                title: 'My AI Fitness Coach',
                cardTitleSize: 3,
                techUsed: [
                  // "react",
                  'nextLight',
                  'openAI',
                  'tailwind',
                  'typeScript',
                  'node',
                  // "python",
                  // "whatsApp",
                  // "next",
                ],
                cardTranslation: 'translateY(-200px) translateX(-40px)',
                description:
                  'An AI driven app to help users with their fitness related goals and plans.',
              }}
            />
          </div>
        ) : (
          <ImageCardMobile
            idx={4}
            imageUrl="/project-images/11.png"
            cardProps={{
              category: 'SaaS',
              categoryColor: 'green',
              title: 'My AI Fitness Coach',

              techUsed: [
                // "react",
                'nextLight',
                'openAI',
                'tailwind',
                'typeScript',
                'node',
                // "python",
                // "whatsApp",
                // "next",
              ],
              description:
                'An AI driven app to help users with their fitness related goals and plans.',
            }}
          />
        )}
      </div>
    </div>
  );
};
