import { useEffect, useState } from "react";

export const useFadeIn = (delay: number, opacity: number = 100) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return isVisible
    ? `opacity-${opacity} transition-opacity duration-1000`
    : "opacity-0";
};
