import { useInView } from "react-intersection-observer";
import { useFullScrollCycle } from "../../hooks/useScroll";
import { getSvg, SvgKeys } from "../image-card/svgs";

export interface ContactInfo {
  title: string;
  svg: SvgKeys;
  scrollSpeed?: number;
  href: string;
  handle: string;
}
interface ContactCardProps {
  info: ContactInfo;
}
const DEFAULT_SCROLL_SPEED = 0.01;

export const ContactCard = ({ info }: ContactCardProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  const { title, svg, scrollSpeed = DEFAULT_SCROLL_SPEED, href, handle } = info;
  const { handleScroll } = useFullScrollCycle();
  return (
    <div
      ref={ref}
      className={`${
        inView ? "opacity-100" : "opacity-0"
      } w-full sm:max-w-auto  sm:w-auto h-auto transition-all duration-500 relative border border-gray-600 border-opacity-40 p-10 rounded-lg`}
      style={{
        ...handleScroll(scrollSpeed),
        background:
          "linear-gradient(to bottom,rgba(228, 221, 221, 0.03),rgba(212, 210, 210, 0.07))",
      }}
    >
      <div className="flex items-center">
        <h2 className="font-title tracking-wider text-white text-2xl font-bold mr-2">
          {title}
        </h2>
        {getSvg(svg)}
      </div>

      <div className="flex items-center">
        <p className="font-body text-gray-400 font-thin text-lg ml-1">
          @ <span className="text-sm">{handle}</span>
        </p>
      </div>

      <div className="flex items-center mt-4">
        <div className="text-gray-200 font-thin text-sm"></div>
      </div>

      <div className="flex items-center mt-2">
        <a
          target="_blank"
          rel="noreferrer"
          href={href}
          className="font-title tracking-wider bg-transparent transition-all duration-500 text-white font-bold px-6 py-2 rounded-lg text-sm border border-white hover:bg-white hover:text-black"
        >
          Visit
        </a>
      </div>
    </div>
  );
};
