import { useEffect, useState } from "react";

const handleScrollEffect = (position: number, speed: number) => {
  return position * speed;
};

export const useFullScrollCycle = () => {
  const [position, setPosition] = useState(0);

  const handleScroll = (speed: number) => {
    return {
      transform: `translateY(-${handleScrollEffect(position, speed)}%)`,
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      setPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { position, handleScroll };
};
