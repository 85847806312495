import { useEffect } from "react";

export const useFirstLoad = (duration: number) => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    // Disable scrolling
    const disableScroll = (e: Event) => e.preventDefault();
    document.body.style.overflow = "hidden";
    document.addEventListener("wheel", disableScroll, { passive: false });
    document.addEventListener("touchmove", disableScroll, { passive: false });

    // Restore scrolling after the duration
    const timeout = setTimeout(() => {
      document.body.style.overflow = "";
      document.removeEventListener("wheel", disableScroll);
      document.removeEventListener("touchmove", disableScroll);
    }, duration);

    return () => {
      clearTimeout(timeout);
      document.body.style.overflow = "";
      document.removeEventListener("wheel", disableScroll);
      document.removeEventListener("touchmove", disableScroll);
    };
  }, [duration]);
};
