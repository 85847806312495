interface TimeLineCardProps {
  title: string;
  date: string;
  company: string;
  isVisible: boolean;
}

export const TimeLineCard = ({
  title,
  date,
  company,
  isVisible,
}: TimeLineCardProps) => {
  return (
    <div
      style={{ transitionDuration: "600ms", width: "auto" }}
      className={`${
        isVisible ? "opacity-100" : "opacity-0"
      } h-50 relative pl-[4.5rem] sm:pl-20 lg:pl-32 py-6 group`}
    >
      <div className="font-title font-medium text-2xl md:text-3xl text-white mb-1 md:mb-0">
        <p className="ml-3 w-52 md:w-60">{title}</p>
      </div>

      <div className="flex flex-col items-start mb-1">
        <span
          className={`font-body absolute left-0 translate-y-0.5  px-2.5 py-0.5 rounded-full text-[10px] md:text-xs  font-thin me-2 border text-gray-700 border-gray-300 dark:text-gray-300`}
        >
          {date}
        </span>
        <div className="flex flex-row text-2xl font-thin text-gray-400 items-center">
          <div className="relative bg-blue-med w-2 h-2 rounded-full mr-2" />

          <p className="font-body text-[1.2rem] lg:text-[1.5rem]">{company}</p>
        </div>
      </div>
    </div>
  );
};
